import { useCallback, useEffect, useMemo, useState } from "react"
import { translate } from "react-i18nify"
import PropTypes from "prop-types"
import _ from "lodash"
import { ImageUploadControl } from "components/form-fields"

export const ImagesUploadControl = ({ images, onLoad }) => {
  const [filesLoading, setFilesLoading] = useState([])

  const loading = useMemo(() => filesLoading?.length > 0, [filesLoading])

  const onFileLoad = useCallback(
    (position, loading) => {
      if (loading) {
        setFilesLoading((prev) => (!_.includes(prev, position) ? [...prev, position] : prev))
      } else {
        setFilesLoading((prev) => _.without(prev, position))
      }
    },
    [setFilesLoading]
  )

  useEffect(() => {
    onLoad(loading)
  }, [loading, onLoad])

  return (
    <>
      {images.map((image) => (
        <ImageUploadControl
          key={image.position}
          name={image.position}
          label={
            image.required
              ? translate(image.label)
              : `${translate(image.label)} ${translate("common.if_available")}`
          }
          required={image.required}
          onLoad={onFileLoad}
        />
      ))}
    </>
  )
}

ImagesUploadControl.propTypes = {
  images: PropTypes.array.isRequired,
  onLoad: PropTypes.func.isRequired,
}

import { useEffect, useState } from "react"
import { translate, Translate } from "react-i18nify"
import { useHistory, useParams } from "react-router-dom"
import PropTypes from "prop-types"
import { Flex, Heading, Stack } from "@chakra-ui/react"
import _ from "lodash"
import { useDocumentTitle, useGetEquipment, useGetOrganization } from "hooks"
import {
  ButtonLink,
  DeclareAsSoldAction,
  EquipmentDetail,
  LoadingSkeleton,
  PageTitle,
} from "components/common"
import { getEquipmentDefaultValues } from "utils"

const View = ({ organization }) => {
  const { setTitle } = useDocumentTitle(translate("common.loading"))
  const history = useHistory()
  const { ocode } = useParams()
  const [equipment, setEquipment] = useState(null)
  const { data: contactOrganization, isLoading: loadingOrganization } = useGetOrganization(
    organization && organization["@id"]
  )
  const { data: equipments, isLoading: loadingEquipment } = useGetEquipment(ocode, true)
  const equipmentName = equipment?.name || ""
  const isLoading = loadingEquipment || loadingOrganization

  useEffect(() => {
    const firstEquipment = _.get(equipments, "hydra:member[0]", null)
    if (firstEquipment) {
      setEquipment(getEquipmentDefaultValues(firstEquipment, contactOrganization, ocode))
    } else if (!loadingEquipment) {
      history.push("/404")
    }
  }, [equipments, loadingEquipment, ocode, contactOrganization, history, setEquipment])

  useEffect(() => {
    if (!isLoading && equipmentName) {
      setTitle(translate("pages.view.title", { name: equipmentName }))
    }
  }, [equipmentName, isLoading, setTitle])

  const declareAsSold = () => {
    setEquipment((prev) => ({
      ...prev,
      currentDigitalTwinOwner: { ...prev.currentDigitalTwinOwner, ownerStatus: "sold" },
    }))
  }

  return (
    <>
      <LoadingSkeleton loading={isLoading} />
      {!isLoading && equipment && (
        <>
          <Stack spacing={0}>
            <PageTitle>{equipment.name}</PageTitle>
            <Heading textAlign="center" size="md">
              <Translate value="common.flashcode" code={ocode} />
            </Heading>
          </Stack>
          <EquipmentDetail equipment={equipment} />

          <Flex justifyContent="space-around" flexWrap="wrap">
            <ButtonLink id="scan-flashcode" to="/scan" size="lg" my={5} mx={2}>
              <Translate value="pages.view.scan_flashcode_button" />
            </ButtonLink>
            <DeclareAsSoldAction equipment={equipment} onSuccess={declareAsSold} />
          </Flex>
        </>
      )}
    </>
  )
}

View.propTypes = {
  organization: PropTypes.object,
}

export default View

import { translate, Translate } from "react-i18nify"
import PropTypes from "prop-types"
import { Stack, Text } from "@chakra-ui/react"
import _ from "lodash"
import { useGetModel } from "hooks"
import { DisplayCharacteristics, DisplayField, DisplayImages } from "components/common"
import { constants } from "utils"

export const EquipmentDetail = ({ equipment }) => {
  const { data: model, isLoading: modelLoading } = useGetModel(
    _.get(equipment, "referentialModel.@id", null)
  )
  const equipmentStatus = equipment.currentDigitalTwinOwner?.ownerStatus

  return (
    <>
      <DisplayImages equipment={equipment} images={constants.EQUIPMENT_SELECTED_PICTURES} />
      <Stack>
        <DisplayField name={translate("entity.name")} value={equipment.name} />
        <DisplayField
          name={translate("entity.asset_category")}
          value={equipment.myProperties?.category?.name}
        />
        <DisplayField name={translate("entity.brand")} value={equipment.referentialBrand?.name} />
        <DisplayField name={translate("entity.model")} value={equipment.referentialModel?.name} />
        <DisplayCharacteristics characteristics={model?.characteristics} loading={modelLoading} />
        <DisplayField
          name={translate("entity.manufacturing_year")}
          value={equipment.manufactureYear}
        />
        <DisplayField
          name={translate("entity.depot_site")}
          value={equipment.ownerDepositSite?.name}
        />
        <DisplayField
          name={translate("entity.owner_organization")}
          value={equipment.newPhysicalEquipmentOwner?.name}
        />
        <DisplayField name={translate("entity.old_reference")} value={equipment.ownerReference} />
        <DisplayField
          name={translate("entity.purchased_with")}
          value={equipment.uperioPurchasedWith}
        />
        {equipmentStatus === "sold" && (
          <Stack mb={2} spacing={0} color="red.500">
            <Text fontWeight="bold">
              <Translate value="pages.view.status" />
            </Text>
            <Text>
              <Translate value="pages.view.sold" />
            </Text>
          </Stack>
        )}
      </Stack>
    </>
  )
}

EquipmentDetail.propTypes = {
  equipment: PropTypes.object.isRequired,
}

import { CloseButton, Flex, Image, Spinner, useColorMode } from "@chakra-ui/react"
import { constants, getFileUrl } from "utils"
import PropTypes from "prop-types"
import _ from "lodash"

export const ImageApi = ({
  file,
  temp,
  alt,
  loading = false,
  onRemove = null,
  width = constants.EQUIPMENT_PICTURE_WIDTH,
  ...rest
}) => {
  const { colorMode } = useColorMode()
  const isDark = colorMode === "dark"
  const textColor = isDark ? "white" : "black"
  const bgColor = isDark ? "blackAlpha.600" : "whiteAlpha.600"

  const onClose = (e) => {
    e.stopPropagation()
    if (_.isFunction(onRemove)) {
      onRemove()
    }
  }

  return (
    <Flex position="relative" justifyContent="center" alignItems="center" w="100%" h="100%">
      <Image src={getFileUrl(file, width)} fallbackSrc={temp} alt={alt} objectFit="contain" />
      {loading && <Spinner position="absolute" top={5} left={5} {...rest} />}
      {_.isFunction(onRemove) && !loading && file && (
        <CloseButton
          position="absolute"
          top={5}
          right={5}
          onClick={onClose}
          textColor={textColor}
          bgColor={bgColor}
        />
      )}
    </Flex>
  )
}

ImageApi.propTypes = {
  file: PropTypes.object,
  temp: PropTypes.string,
  alt: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  onRemove: PropTypes.func,
  width: PropTypes.number,
}

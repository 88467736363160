import { Box, SkeletonCircle, SkeletonText } from "@chakra-ui/react"
import PropTypes from "prop-types"

export const LoadingSkeleton = ({ loading = true }) => {
  if (!loading) {
    return null
  }

  return (
    <Box padding="6" boxShadow="lg" flexGrow={1}>
      <SkeletonCircle size="10" />
      <SkeletonText mt="4" noOfLines={12} spacing="4" />
    </Box>
  )
}

LoadingSkeleton.propTypes = {
  loading: PropTypes.bool,
}

import { useGetProfile } from "hooks"
import { LoadingLayout } from "layouts"

export const AuthContact = ({ PageComponent, ...rest }) => {
  const { contact, organization, role, loading } = useGetProfile()

  if (loading || organization === null) {
    return <LoadingLayout />
  }

  return <PageComponent contact={contact} organization={organization} role={role} {...rest} />
}

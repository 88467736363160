import React, { StrictMode } from "react"
import ReactDOM from "react-dom"
import { addLocales, setLocale, setTranslations } from "react-i18nify"
import packageJson from "../package.json"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import * as serviceWorker from "./serviceWorker"
import { ColorModeScript } from "@chakra-ui/react"
import * as Sentry from "@sentry/react"
import de from "date-fns/locale/de"
import en from "date-fns/locale/en-US"
import fr from "date-fns/locale/fr"
import { translations } from "translations"
import { constants } from "utils"

const sentryDsn = process.env.REACT_APP_SENTRY_DSN

if (sentryDsn) {
  Sentry.init({
    dsn: sentryDsn,
    release: "uperio-assets@" + packageJson.version,
  })
}

setTranslations(translations)
addLocales({ en, fr, de })
setLocale(constants.DEFAULT_LANG)

ReactDOM.render(
  <StrictMode>
    <ColorModeScript />
    <App />
  </StrictMode>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import { translate, Translate } from "react-i18nify"
import { Flex, Heading, Text, useColorMode } from "@chakra-ui/react"
import { useDocumentTitle, useWelcomUser } from "hooks"
import { ButtonLink } from "components/common"
import { UperioIcon } from "components/icons"

const Home = () => {
  useWelcomUser()
  useDocumentTitle(translate("pages.home.title"))
  const { colorMode } = useColorMode()

  const logoColors =
    colorMode === "dark"
      ? {
          logoColor: "#fff",
          baselineColor: "#fff",
        }
      : {
          logoColor: "#021d49",
          baselineColor: "#ff6b00",
        }

  return (
    <>
      <Heading as="h1" align="center">
        <UperioIcon w={250} h={150} {...logoColors} />
      </Heading>

      <Flex direction="column" justifyContent="center" alignItems="center">
        <Text textAlign="center" fontSize="xl" mb={5} px={10}>
          <Translate value="pages.home.scan_flashcode_text" />
        </Text>

        <ButtonLink id="scan-flashcode" to="/scan" size="lg" my={5}>
          <Translate value="pages.home.scan_flashcode_button" />
        </ButtonLink>
      </Flex>
    </>
  )
}

export default Home

import { Stack, useColorMode } from "@chakra-ui/react"
import { LoadingSkeleton } from "components/common"
import { Container, Header, Main } from "layouts/common"

export const LoadingLayout = () => {
  const { colorMode } = useColorMode()
  const bgColor = { light: "gray.50", dark: "gray.900" }
  const color = { light: "black", dark: "white" }

  return (
    <Stack bg={bgColor[colorMode]} color={color[colorMode]}>
      <Container minHeight="100vh">
        <Header />
        <Main>
          <LoadingSkeleton />
        </Main>
      </Container>
    </Stack>
  )
}

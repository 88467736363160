import { useApiGet } from "apis/sharemat"

export const useGetModel = (
  id,
  redirectIf404 = false,
  displayError = false,
  queryOptions = null
) => {
  return useApiGet(id, null, redirectIf404, displayError, queryOptions)
}

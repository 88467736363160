import { Translate } from "react-i18nify"
import { Button, ButtonGroup, Flex, Text } from "@chakra-ui/react"
import _ from "lodash"
import { PageTitle } from "components/common"

export const ErrorFallback = ({ error, resetError }) => {
  return (
    <Flex direction="column" flexGrow="1" justifyContent="center" alignItems="center">
      <PageTitle my={10}>
        <Translate value="pages.error.title" />
      </PageTitle>
      <Text mb={10} textAlign="center">
        {error ? error.toString() : <Translate value="pages.error.description" />}
      </Text>
      {_.isFunction(resetError) && (
        <ButtonGroup display="flex" flexDirection="column" alignItems="center">
          <Button onClick={resetError}>
            <Translate value="pages.error.retry" />
          </Button>
        </ButtonGroup>
      )}
    </Flex>
  )
}

/*global Module*/
import { useCallback, useEffect, useRef, useState } from "react"
import { Translate } from "react-i18nify"
import PropTypes from "prop-types"
import { Box, Flex, Text } from "@chakra-ui/react"
import { CameraNotSupported } from "components/common"
import { strDef } from "utils"

/**
 * Composant de scan du ocode.
 * @param onDetect: fonction appelée avec le ocode détecté
 */
export const ScanOcode = ({ onDetect }) => {
  const [isMediaSupport, setIsMediaSupport] = useState(null)
  const [displayCamera, setDisplayCamera] = useState(null)
  const videoRef = useRef()
  const canvasRef = useRef()
  const ctxRef = useRef()
  const timerRef = useRef()

  // appelé quand le ocode a été détecté
  const process = useCallback(
    (e) => {
      // le ocode a été détecté
      window.removeEventListener("ocode", process, false)
      if (timerRef.current) {
        clearTimeout(timerRef.current)
        timerRef.current = null
      }
      if (strDef(window.localstream)) {
        window.localstream.getTracks()[0].stop()
      }

      onDetect(e.detail)
    },
    [onDetect]
  )

  // appelé après le 1er render : ajout de fonctions nécessaires à detectOcode
  useEffect(() => {
    window.isWebAssemblySupport = function isWebAssemblySupport() {
      try {
        if (typeof WebAssembly === "object" && typeof WebAssembly.instantiate === "function") {
          const module = new WebAssembly.Module(
            Uint8Array.of(0x0, 0x61, 0x73, 0x6d, 0x01, 0x00, 0x00, 0x00)
          )
          if (module instanceof WebAssembly.Module) {
            return true
          } else {
            return false
          }
        }
      } catch (e) {
        return false
      }
      return false
    }

    window.detectOcode = function detectOcode(uint8ArrData, width, height) {
      const numBytes = uint8ArrData.length
      const dataPtr = Module._create_buffer(width, height)
      const dataOnHeap = new Uint8Array(Module.HEAPU8.buffer, dataPtr, numBytes)
      dataOnHeap.set(uint8ArrData)
      Module._detectOcode(dataOnHeap.byteOffset, width, height)
      Module._destroy_buffer(dataPtr)
    }

    Module["processResultOcode"] = (ocode_id) => {
      if (String(ocode_id) !== "0") {
        // création d'un custom event pour transmettre le o°code à celui qui l'écoute
        var event = new CustomEvent("ocode", { detail: ocode_id })
        window.dispatchEvent(event)
      }
    }

    Module["processDraw"] = (symbol, type) => {}

    navigator.getUserMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      strDef(navigator.mediaDevices)
        ? navigator.mediaDevices.getUserMedia
        : null || null

    if (navigator.getUserMedia === null || window.isWebAssemblySupport() === false) {
      // media ou webassembly non supporté sur ce navigateur
      setIsMediaSupport(false)
    } else {
      // listener lorsque le ocode est détecté
      window.addEventListener("ocode", process, false)

      // on lance la caméra
      setIsMediaSupport(true)
      setDisplayCamera(true)
    }

    return () => {
      // cleanup
      if (strDef(window.localstream)) {
        window.localstream.getTracks()[0].stop()
      }
    }
  }, [process])

  // appelé quand la valeur de displayCamera change
  useEffect(() => {
    if (displayCamera) {
      ctxRef.current = canvasRef.current.getContext("2d")
      navigator.mediaDevices
        .getUserMedia({ video: { facingMode: "environment" }, audio: false })
        .then((stream) => {
          videoRef.current.srcObject = stream
          window.localstream = stream
        })
        .catch((error) => {
          console.error("Something is broken like permission not authorized.", error)
          setIsMediaSupport(false)
          setDisplayCamera(false)
        })
    }
  }, [displayCamera, process, setIsMediaSupport, setDisplayCamera])

  const onPlay = () => {
    let ratio = videoRef.current.videoWidth / videoRef.current.videoHeight
    let w = 1200
    let h = parseInt(w / ratio)
    canvasRef.current.style.width = w + "px"
    canvasRef.current.style.height = h + "px"
    canvasRef.current.width = w
    canvasRef.current.height = h

    if (isMediaSupport) {
      cameraDraw()
    }
  }

  const cameraDraw = () => {
    if (videoRef.current === null || videoRef.current.paused || videoRef.current.ended) {
      return
    }
    ctxRef.current.drawImage(
      videoRef.current,
      0,
      0,
      canvasRef.current.width,
      canvasRef.current.height
    )
    let image = canvasRef.current
      .getContext("2d")
      .getImageData(0, 0, canvasRef.current.width, canvasRef.current.height)
    let buffer = new ArrayBuffer(image.data.length)
    let uint8 = new Uint8Array(buffer)
    uint8.set(image.data)
    try {
      window.detectOcode(uint8, image.width, image.height)
    } catch (e) {
      console.error("cameraDraw", e)
    }
    timerRef.current = setTimeout(cameraDraw, 0)
  }

  return (
    <>
      {isMediaSupport === false && <CameraNotSupported />}
      {displayCamera && (
        <Box
          position="relative"
          flexGrow="1"
          ml="-1.6rem"
          mr="-1.6rem"
          mt="-2rem"
          mb="-4rem"
          overflow="hidden"
        >
          <Box
            id="live"
            ref={videoRef}
            as="video"
            position="absolute"
            display="block"
            w="100%"
            h="100%"
            objectFit="cover"
            playsInline
            autoPlay
            onPlay={onPlay}
          />
          <Box
            id="canvas"
            ref={canvasRef}
            as="canvas"
            position="absolute"
            w="100%"
            h="100%"
            left="50%"
            top="50%"
            transform="translate(-50%, -50%)"
            opacity="0"
          />
          <Flex
            id="mire"
            position="absolute"
            w="100%"
            h="100%"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              id="mireoverlay"
              position="absolute"
              display="block"
              w="100px"
              h="100px"
              borderRadius="50px"
              boxShadow="0px 0px 8px  #fff"
              backgroundColor="rgba(0,0,0,0.2)"
              zIndex="2"
            />
          </Flex>
          <Flex
            id="centerme"
            position="absolute"
            w="100%"
            h="100%"
            alignItems="flex-end"
            justifyContent="center"
          >
            <Flex alignItems="center" bg="blackAlpha.600">
              <Text px={8} py={4} fontSize="2xl" color="white" textAlign="center" lineHeight={1.25}>
                <Translate value="pages.scan.please_scan" />
              </Text>
            </Flex>
          </Flex>
        </Box>
      )}
    </>
  )
}

ScanOcode.propTypes = {
  onDetect: PropTypes.func.isRequired,
}

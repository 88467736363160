import { useApiGet } from "apis/sharemat"
import { useHydraList } from "hooks"
import { getHydraId } from "utils"

export const useGetCategories = ({
  organization,
  equipmentType = "small_equipment",
  pageSize = 1000,
  redirectIf404 = false,
}) => {
  const params = { organization: getHydraId(organization), equipmentType, pageSize }
  const { data: hydraData, isLoading, error } = useApiGet(
    `/equipment_categories`,
    params,
    redirectIf404
  )
  const data = useHydraList(hydraData)

  return { data, isLoading, error }
}

import { useApiGet } from "apis/sharemat"
import { useHydraList } from "hooks"
import { getHydraId } from "utils"

export const useGetSites = ({
  organization,
  type = "home_site",
  pageSize = 1000,
  redirectIf404 = false,
}) => {
  const params = {
    "arrayContains[types]": type,
    "order[name]": "asc",
    pageSize,
  }
  const {
    data: hydraData,
    isLoading,
    error,
  } = useApiGet(`/organizations/${getHydraId(organization)}/sites`, params, redirectIf404)
  const data = useHydraList(hydraData)

  return { data, isLoading, error }
}

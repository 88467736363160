import { useEffect } from "react"
import { translate } from "react-i18nify"
import { useAuth0 } from "@auth0/auth0-react"
import { useToast } from "@chakra-ui/react"
import { constants, getLocalStorageItem, setLocalStorageItem } from "utils"

export const useWelcomUser = () => {
  const { user } = useAuth0()
  const toast = useToast()
  const welcome = !!getLocalStorageItem("welcome")

  useEffect(() => {
    if (user && !welcome) {
      const metadata = user["https://sharemat.eu/claims/user_metadata"]
      const name = metadata?.firstname || user.name
      toast({
        title: translate("application.welcome", { name }),
        status: "info",
        duration: constants.TOAST_INFO_DURATION,
        isClosable: true,
      })
      setLocalStorageItem("welcome", true)
    }
  }, [user, welcome]) // eslint-disable-line react-hooks/exhaustive-deps

  return welcome
}

import { getLocale } from "react-i18nify"
import PropTypes from "prop-types"
import { Flex, Spinner, Text } from "@chakra-ui/react"
import _ from "lodash"
import { getCharacteristicValue } from "utils"

const DisplayCharacteristic = ({ characteristic, lang }) => {
  const label = _.get(characteristic, `characteristicType.label.${lang}`, "")
  const labelText = label ?? `${label}: `
  const value = getCharacteristicValue(characteristic)

  return (
    <Flex mr={3}>
      <Text fontSize="xs" fontStyle="italic" fontWeight="bold" mr={1}>
        {labelText}
      </Text>
      <Text fontSize="xs" fontStyle="italic">
        {value}
      </Text>
    </Flex>
  )
}

export const DisplayCharacteristics = ({ characteristics, loading = false }) => {
  const lang = getLocale()

  if (loading) {
    return <Spinner />
  }

  if (!characteristics) {
    return null
  }

  return (
    <Flex flexWrap="wrap" pl={3}>
      {characteristics.map((characteristic, index) => (
        <DisplayCharacteristic key={index} characteristic={characteristic} lang={lang} />
      ))}
    </Flex>
  )
}

DisplayCharacteristics.propTypes = {
  characteristics: PropTypes.array,
  loading: PropTypes.bool,
}

import { MultiSelectControl } from "components/form-fields"
import PropTypes from "prop-types"

export const MultiSelectControlHook = ({
  name,
  label,
  useGetOptions,
  useGetOptionsProps = {},
  reset = null,
  ...rest
}) => {
  const { data, isLoading } = useGetOptions(useGetOptionsProps)

  return (
    <MultiSelectControl
      name={name}
      label={label}
      options={data}
      isLoading={isLoading}
      reset={reset}
      {...rest}
    />
  )
}

MultiSelectControlHook.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  useGetOptions: PropTypes.func.isRequired,
  useGetOptionsProps: PropTypes.object,
  reset: PropTypes.func,
}

import { Text } from "@chakra-ui/react"
import { constants } from "utils/constants"
import _ from "lodash"

export const displayHydraError = (
  display,
  error,
  defaultTitle = "Something went wrong",
  status = "error"
) => {
  const title = _.get(error, "response.data.hydra:title", defaultTitle)
  const description = _.truncate(_.get(error, "response.data.hydra:description", error?.message), {
    length: 100,
  })
  display({
    title,
    description: description ? <Text wordBreak="break-word">{description}</Text> : "",
    status,
    duration: constants.TOAST_ERROR_DURATION,
    isClosable: true,
  })
}

export const getHydraItems = (hydraResult, flatten = false) => {
  const hydraMembers = _.get(hydraResult, "hydra:member")

  if (flatten) {
    // remise à plat
    let groupedValues = []
    for (let index in hydraMembers) {
      let newItem = _.omit(hydraMembers[index], ["children"])
      newItem.group = true
      groupedValues.push(newItem)
      if (_.has(hydraMembers[index], "children")) {
        for (let childIndex in hydraMembers[index].children) {
          groupedValues.push(_.clone(hydraMembers[index].children[childIndex]))
        }
      }
    }

    return groupedValues
  }

  return hydraMembers ?? []
}

export const getHydraId = (object) => {
  if (Array.isArray(object) && object.length === 0) {
    return null
  }
  if (object && object["@id"]) {
    const str = object["@id"]
    return str.substring(str.lastIndexOf("/") + 1)
  }
  if (object && object.lastIndexOf && object.substring) {
    return object.substring(object.lastIndexOf("/") + 1)
  }

  return object
}

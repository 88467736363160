import { extendTheme, withDefaultColorScheme } from "@chakra-ui/react"
import { createBreakpoints } from "@chakra-ui/theme-tools"
import { Button, Link } from "theme/components"

const fonts = { mono: `'Menlo', monospace` }

const breakpoints = createBreakpoints({
  sm: "40em",
  md: "52em",
  lg: "64em",
  xl: "80em",
})

const theme = extendTheme(
  {
    color: {
      black: "#16161d",
    },
    fonts,
    breakpoints,
    components: {
      Button,
      Link,
    },
  },
  withDefaultColorScheme({ colorScheme: "blue" })
)

export default theme

import { useEffect, useState } from "react"
import { translate, Translate } from "react-i18nify"
import { useHistory, useParams } from "react-router-dom"
import PropTypes from "prop-types"
import { Heading, Stack, useToast } from "@chakra-ui/react"
import _ from "lodash"
import {
  useCreateEquipment,
  useDocumentTitle,
  useGetEquipment,
  useGetOrganization,
  useUpdateEquipment,
} from "hooks"
import { LoadingSkeleton, PageTitle } from "components/common"
import { EquipmentForm, EquipmentPreview } from "components/forms"
import { constants, getFormattedEquipment, getFormattedEquipmentName } from "utils"

const Save = ({ organization, role }) => {
  const { setTitle } = useDocumentTitle(translate("common.loading"))
  const { ocode } = useParams()
  const [equipment, setEquipment] = useState(null)
  const [currentEquipment, setCurrentEquipment] = useState(null)
  const [previewEquipment, setPreviewEquipment] = useState(null)
  const { data: contactOrganization, isLoading: loadingOrganization } = useGetOrganization(
    organization && organization["@id"]
  )
  const { data: equipments, isLoading: loadingEquipment } = useGetEquipment(ocode, false)
  const { mutate: createEquipment, isLoading: createEquipmentLoading } = useCreateEquipment(ocode)
  const { mutate: updateEquipment, isLoading: updateEquipmentLoading } = useUpdateEquipment(ocode)
  const history = useHistory()
  const toast = useToast()
  const equipmentName = equipment?.name || ""
  const isLoading = loadingOrganization || loadingEquipment

  useEffect(() => {
    const firstEquipment = _.get(equipments, "hydra:member[0]", null)
    if (firstEquipment) {
      setEquipment(firstEquipment)
    }
  }, [equipments, setEquipment])

  useEffect(() => {
    if (!isLoading) {
      if (equipmentName) {
        // Seuls le role admin peut accéder au formulaire d'édition
        if (role !== "admin") {
          history.push(`/view/${ocode}`)
        }
        setTitle(translate("pages.save.edit", { name: equipmentName }))
      } else {
        setTitle(translate("pages.save.create"))
      }
    }
  }, [equipmentName, isLoading, role, history, ocode, setTitle])

  const onNext = (values) => {
    setPreviewEquipment({
      ...values,
      name: getFormattedEquipmentName(values),
    })
    window.scrollTo(0, 0)
  }

  const goBack = () => {
    setCurrentEquipment(previewEquipment)
    setPreviewEquipment(null)
    window.scrollTo(0, 0)
  }

  const onSubmit = (values) => {
    const formattedEquipment = getFormattedEquipment(values)

    if (formattedEquipment["@id"]) {
      updateEquipment(
        { put_url: formattedEquipment["@id"], ...formattedEquipment },
        {
          onSuccess: (newEquipment) => {
            toast({
              title: translate("pages.save.update_success"),
              description: "",
              status: "success",
              duration: constants.TOAST_ERROR_DURATION,
              isClosable: true,
            })
            history.push("/")
          },
        }
      )
    } else {
      createEquipment(
        {
          ...formattedEquipment,
          uperioInitialSite: values.ownerDepositSite?.name || "",
        },
        {
          onSuccess: (newEquipment) => {
            toast({
              title: translate("pages.save.create_success"),
              description: "",
              status: "success",
              duration: constants.TOAST_ERROR_DURATION,
              isClosable: true,
            })
            history.push("/")
          },
        }
      )
    }
  }

  return (
    <>
      <LoadingSkeleton loading={isLoading} />
      {!isLoading && !previewEquipment && (
        <>
          <Stack spacing={0}>
            <PageTitle>
              {equipment ? (
                <Translate value="pages.save.edit_title" />
              ) : (
                <Translate value="pages.save.create_title" />
              )}
            </PageTitle>
            <Heading textAlign="center" size="md">
              <Translate value="common.flashcode" code={ocode} />
            </Heading>
          </Stack>
          {contactOrganization && (
            <EquipmentForm
              ocode={ocode}
              equipment={currentEquipment || equipment}
              organization={contactOrganization}
              onSubmit={onNext}
            />
          )}
        </>
      )}
      {!isLoading && previewEquipment && (
        <>
          <Stack spacing={0}>
            <PageTitle>
              <Translate value="pages.save.title" />
            </PageTitle>
            <Heading textAlign="center" size="md">
              <Translate value="common.flashcode" code={ocode} />
            </Heading>
          </Stack>
          <EquipmentPreview
            equipment={previewEquipment}
            onPrev={goBack}
            onSubmit={onSubmit}
            loading={createEquipmentLoading || updateEquipmentLoading}
          />
        </>
      )}
    </>
  )
}

Save.propTypes = {
  organization: PropTypes.object,
}

export default Save

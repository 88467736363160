import { useMemo, useState } from "react"
import { Form } from "react-final-form"
import { translate, Translate } from "react-i18nify"
import PropTypes from "prop-types"
import { ChevronRightIcon } from "@chakra-ui/icons"
import { Box, Button, Center, Text } from "@chakra-ui/react"
import createDecorator from "final-form-focus"
import { useGetBrands, useGetCategories, useGetModels, useGetSites } from "hooks"
import {
  ImagesUploadControl,
  InputControl,
  MultiSelectControl,
  MultiSelectControlHook,
  NumberControl,
  ReferenceControl,
} from "components/form-fields"
import { constants, getEquipmentDefaultValues, getFormattedOrganizationRelations } from "utils"

const focusOnErrors = createDecorator()

const validateForm = (values) => {
  const errors = {}
  const requiredFields = [
    "referentialBrand",
    "referentialModel",
    "ownerDepositSite",
    "newPhysicalEquipmentOwner",
    ...constants.EQUIPMENT_SELECTED_PICTURES.filter((photo) => photo.required).map(
      (photo) => photo.position
    ),
  ]

  const myPropRequiredFields = ["category"]

  requiredFields.forEach((field) => {
    if (!values[field] || values[field].length === 0) {
      errors[field] = "Required"
    }
  })

  myPropRequiredFields.forEach((field) => {
    if (!values.myProperties?.[field] || values.myProperties?.[field].length === 0) {
      errors[field] = "Required"
    }
  })

  return errors
}

export const EquipmentForm = ({ ocode, organization, onSubmit, equipment = null }) => {
  const [filesLoading, setFilesLoading] = useState(false)
  const organizations = getFormattedOrganizationRelations(organization?.myRelations)

  const defaultValues = useMemo(
    () => getEquipmentDefaultValues(equipment, organization, ocode),
    [equipment, organization, ocode]
  )

  return (
    <Form
      onSubmit={onSubmit}
      decorators={[focusOnErrors]}
      validate={validateForm}
      initialValues={defaultValues}
      mutators={{
        resetValue: ([field], state, { changeValue }) => {
          changeValue(state, field, () => null)
        },
      }}
      render={({ form: finalFormApi, handleSubmit, submitting, values }) => (
        <Box
          as="form"
          px={4}
          rounded="lg"
          onSubmit={(event) => {
            const invalidField = finalFormApi
              .getRegisteredFields()
              .find((field) => finalFormApi.getFieldState(field).invalid)

            if (invalidField) {
              const targetInput = document.querySelector(`[name="${invalidField}"]`)
              const targetLabel =
                document.querySelector(`label[for="${invalidField}"]`) || targetInput
              if (targetInput) {
                targetInput.focus({ preventScroll: true })
                targetLabel.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                })
              }
            }

            handleSubmit(event)
          }}
        >
          <InputControl name="erpId" type="hidden" />
          <MultiSelectControlHook
            name="myProperties.category"
            label={`${translate("entity.asset_category")} *`}
            isMulti={false}
            useGetOptions={useGetCategories}
            useGetOptionsProps={{ organization }}
            reset={finalFormApi.mutators.resetValue}
          />
          <MultiSelectControlHook
            name="referentialBrand"
            label={`${translate("entity.brand")} *`}
            isMulti={false}
            useGetOptions={useGetBrands}
            useGetOptionsProps={{ organization }}
            reset={finalFormApi.mutators.resetValue}
          />
          <MultiSelectControlHook
            name="referentialModel"
            label={`${translate("entity.model")} *`}
            isMulti={false}
            useGetOptions={useGetModels}
            useGetOptionsProps={{
              category: values?.myProperties?.category,
              brand: values?.referentialBrand,
              enabled: !!values?.myProperties?.category && !!values?.referentialBrand,
            }}
            isDisabled={!values?.myProperties?.category || !values?.referentialBrand}
            reset={finalFormApi.mutators.resetValue}
          />
          <NumberControl
            name="manufactureYear"
            label={translate("pages.save.manufacturing_year")}
            min="1900"
            max="2099"
            step="1"
            placeholder="YYYY"
          />
          <MultiSelectControlHook
            name="ownerDepositSite"
            label={`${translate("entity.depot_site")} *`}
            isMulti={false}
            useGetOptions={useGetSites}
            useGetOptionsProps={{ organization, type: "home_site" }}
            reset={finalFormApi.mutators.resetValue}
          />
          <MultiSelectControl
            name="newPhysicalEquipmentOwner"
            label={`${translate("entity.owner_organization")} *`}
            isMulti={false}
            options={organizations}
            valueKey="name"
            isLoading={false}
            reset={finalFormApi.mutators.resetValue}
          />
          <ReferenceControl
            name="ownerReference"
            label={translate("entity.old_reference")}
            type="text"
          />
          <InputControl
            name="uperioPurchasedWith"
            label={translate("entity.purchased_with")}
            type="text"
          />
          <ImagesUploadControl
            images={constants.EQUIPMENT_SELECTED_PICTURES}
            onLoad={setFilesLoading}
          />
          <Text my={5} textAlign="center" fontSize="sm">
            <Translate value="common.required_fields" />
          </Text>
          <Center
            display="flex"
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            spacing={4}
          >
            <Button
              isLoading={submitting || filesLoading}
              loadingText={translate("common.loading")}
              type="submit"
              name="submit"
              rightIcon={<ChevronRightIcon />}
              translate="no"
            >
              <Translate value="common.next" />
            </Button>
          </Center>
        </Box>
      )}
    />
  )
}

EquipmentForm.propTypes = {
  ocode: PropTypes.string.isRequired,
  organization: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  equipment: PropTypes.object,
}

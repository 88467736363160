import { translate } from "react-i18nify"
import PropTypes from "prop-types"
import { Stack, Text } from "@chakra-ui/react"
import _ from "lodash"
import { strDef } from "utils"

export const DisplayField = ({
  name,
  value,
  defaultValue = translate("common.nc"),
  formatter = null,
}) => (
  <Stack mb={2} spacing={0}>
    <Text fontWeight="bold">{name}</Text>
    <Text>
      {strDef(value) ? (_.isFunction(formatter) ? formatter(value) : value) : defaultValue}
    </Text>
  </Stack>
)

DisplayField.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  formatter: PropTypes.func,
}

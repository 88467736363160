import { constants } from "./constants"
import _ from "lodash"

export const getFormattedOrganizationRelations = (
  organizations,
  labelKey = "name",
  valueKey = "@id"
) => {
  const result = []
  if (!organizations || !_.isArray(organizations)) {
    return result
  }
  organizations.forEach((organization) => {
    if (organization.visibleOrganization) {
      result.push({
        type: "visibleOrganization",
        [labelKey]: organization.visibleOrganization.name,
        [valueKey]: organization["@id"],
      })
    } else {
      result.push({
        type: "visiblePseudoOrganizationName",
        [labelKey]: organization.visiblePseudoOrganizationName,
        [valueKey]: organization["@id"],
      })
    }
  })

  return result
}

export const getFileUrl = (
  file,
  width = constants.EQUIPMENT_PICTURE_WIDTH,
  baseUrl = process.env.REACT_APP_API_BASEURL
) => {
  if (!file) {
    return ""
  }
  let url =
    process.env.NODE_ENV === "development"
      ? `${baseUrl}/uploads/${file.path}`
      : `${baseUrl}/static/${file.path}`
  if (width) {
    url += `?w=${width}`
  }
  return url
}

export const getEquipmentDefaultValues = (equipment, organization, erpId) => {
  const defaultHomeSite = _.includes(organization?.defaultSite?.types, "home_site")
    ? organization?.defaultSite
    : null

  let currentPEO = null
  organization?.myRelations?.forEach((relation) => {
    if (
      relation.visibleOrganization &&
      relation.visibleOrganization["@id"] ===
        equipment?.currentPhysicalEquipmentOwner?.organization?.["@id"]
    ) {
      currentPEO = {
        type: "visibleOrganization",
        name: relation.visibleOrganization.name,
        "@id": relation["@id"],
      }
    } else if (
      relation.visiblePseudoOrganizationName &&
      relation.visiblePseudoOrganizationName ===
        equipment?.currentPhysicalEquipmentOwner?.pseudoOrganizationName
    ) {
      currentPEO = {
        type: "visiblePseudoOrganizationName",
        name: relation.visiblePseudoOrganizationName,
        "@id": relation["@id"],
      }
    }
  })

  const manufactureYear =
    equipment?.manufactureYear ??
    (equipment?.manufactureDate ? new Date(equipment?.manufactureDate).getFullYear() : null)

  const defaultValues = {
    ...equipment,
    erpId,
    manufactureYear,
    organizationOwner: equipment?.organizationOwner || organization,
    ownerDepositSite:
      equipment?.ownerDepositSite ||
      equipment?.currentDigitalTwinOwner?.ownerDepositSite ||
      defaultHomeSite,
    ownerReference: equipment?.ownerReference || equipment?.currentDigitalTwinOwner?.ownerReference,
    newPhysicalEquipmentOwner: equipment?.newPhysicalEquipmentOwner || currentPEO,
  }
  constants.EQUIPMENT_SELECTED_PICTURES.forEach((picture) => {
    if (equipment && !_.isUndefined(equipment[picture.position])) {
      defaultValues[picture.position] = equipment[picture.position]
    } else if (!equipment || !equipment[picture.position]) {
      defaultValues[picture.position] = _.find(equipment?.pictures, { position: picture.position })
    }
  })

  return defaultValues
}

export const getFormattedEquipmentName = (equipment) => {
  const category = _.get(equipment, "myProperties.category.name", "")
  const brand = _.get(equipment, "referentialBrand.name", "")
  const model = _.get(equipment, "referentialModel.name", "")
  return `${category}${brand ? ` ${brand}` : ""}${model ? ` ${model}` : ""}`
}

export const getFormattedEquipment = (equipment) => {
  const formattedEquipment = {
    erpId: equipment.erpId,
    name: getFormattedEquipmentName(equipment),
    type: constants.EQUIPMENT_DEFAULT_TYPE,
    status: constants.EQUIPMENT_DEFAULT_STATUS,
    referentialBrand: _.get(equipment, "referentialBrand.@id", null),
    referentialModel: _.get(equipment, "referentialModel.@id", null),
    organizationOwner: _.get(equipment, "organizationOwner.@id", null),
    uperioPurchasedWith: _.get(equipment, "uperioPurchasedWith", null),
    serialNumber: equipment.erpId,
    ownerReference: _.get(equipment, "ownerReference", null),
    siteOwner: _.get(equipment, "ownerDepositSite.@id", null),
    depositSiteOwner: _.get(equipment, "ownerDepositSite.@id", null),
    currentPhysicalEquipmentOwner: _.get(equipment, "newPhysicalEquipmentOwner.@id", null),
    pictures: getFormPictures(equipment),
    myProperties: {
      category: _.get(equipment, "myProperties.category.@id", null),
    },
  }

  if (equipment["@id"]) {
    formattedEquipment["@id"] = equipment["@id"]
  }

  // si l'année de manufactureDate n'a pas changée, on n'ajoute pas ce paramètre
  const oldManufactureYear = equipment.manufactureDate
    ? new Date(equipment.manufactureDate).getFullYear()
    : null
  if (equipment.manufactureYear !== oldManufactureYear) {
    formattedEquipment.manufactureDate = equipment.manufactureYear
      ? `${equipment.manufactureYear}-01-01`
      : null
  }

  return formattedEquipment
}

export const getFormPictures = (equipment) => {
  // Plusieurs cas possibles
  // Ajout d'1 image alors qu'il n'y en avait pas : pictures = [ { file: '/files/1', position: 'mainImage' } ]
  // Suppression de toutes les images : pictures = []
  // Ajout d'1 image alors qu'il y en avait déjà 1 : pictures = [ '/equipment_picture_files/1', { file: '/files/2', position: 'frontImage' } ]
  let pictures = []
  const oldPictures = equipment.pictures
  constants.EQUIPMENT_SELECTED_PICTURES.forEach((picture) => {
    const currentPicture = equipment[picture.position]
    if (currentPicture) {
      const finded = _.find(oldPictures, (op) => op.position === picture.position)
      if (!finded) {
        pictures.push({ position: picture.position, file: currentPicture.file["@id"] })
      } else {
        if (finded.file["@id"] === currentPicture.file["@id"]) {
          pictures.push(finded["@id"])
        } else {
          pictures.push({ position: picture.position, file: currentPicture.file["@id"] })
        }
      }
    }
  })

  return pictures
}

export const getCharacteristicValue = (characteristic) => {
  const value = characteristic?.value
  if (_.isUndefined(value) || _.isNull(value)) {
    return constants.EMPTY_VALUE
  } else {
    switch (characteristic?.characteristicType?.type) {
      case "decimal":
        return parseFloat(value)
      case "integer":
        return parseInt(value, 10)
      case "date":
        return value ? new Date(value).toDateString() : null
      case "daterange":
        const startDate = value?.startDate
          ? new Date(value.startDate).toDateString()
          : constants.EMPTY_VALUE
        const endDate = value?.endDate
          ? new Date(value.endDate).toDateString()
          : constants.EMPTY_VALUE
        return `${startDate} - ${endDate}`
      case "string":
        return value?.value ? value?.value : value // cas particulier pour les enums passés en string
      case "boolean":
      case "text":
      default:
        return value
    }
  }
}

import { getLocale, setLocale, translate } from "react-i18nify"
import { Select } from "@chakra-ui/react"
import { useUpdateMe } from "hooks"
import { constants } from "utils"

export const LangSwitcher = () => {
  const { mutate: updateMe } = useUpdateMe()
  const locale = getLocale()

  const onChange = (event) => {
    const newLang = event.target.value

    if (newLang !== locale) {
      setLocale(newLang)
      updateMe({ put_url: "/account/me", account: { defaultLocale: newLang } })
    }
  }

  return (
    <Select minW="130" maxW="160" value={locale} onChange={onChange}>
      {constants.LANGS.map((l) => (
        <option key={l} value={l}>
          {translate(`locales.${l}`)}
        </option>
      ))}
    </Select>
  )
}

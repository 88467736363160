import { translate, Translate } from "react-i18nify"
import PropTypes from "prop-types"
import { Flex, Text } from "@chakra-ui/react"
import { useDocumentTitle } from "hooks"
import { Link, PageTitle } from "components/common"

const Error = ({ code }) => {
  useDocumentTitle(translate("pages.error.page_title", { code }))

  return (
    <Flex direction="column" flexGrow="1" justifyContent="center" alignItems="center">
      <PageTitle my={10}>
        <Translate value="pages.error.page_title" code={code} />
      </PageTitle>
      {code === 404 && (
        <>
          <Text textAlign="center" mb={2}>
            <Translate value="pages.error.not_found" />
          </Text>
          <Text textAlign="center">
            <Link to="/">
              <Translate value="pages.error.got_back_to_home" />
            </Link>
          </Text>
        </>
      )}
      {(code === 401 || code === 403) && (
        <>
          <Text textAlign="center" mb={2}>
            <Translate value="pages.error.not_authorized" />
          </Text>
          <Text textAlign="center">
            <Link to="/">
              <Translate value="pages.error.got_back_to_home" />
            </Link>
          </Text>
        </>
      )}
      {code !== 401 && code !== 403 && code !== 404 && (
        <>
          <Text textAlign="center" mb={2}>
            <Translate value="pages.error.other_error" />
          </Text>{" "}
          <Text textAlign="center">
            <Link to="/">
              <Translate value="pages.error.got_back_to_home" />
            </Link>
          </Text>
        </>
      )}
    </Flex>
  )
}

Error.propTypes = {
  code: PropTypes.number.isRequired,
}

export default Error

import { getLocale } from "react-i18nify"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useHistory } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"
import { useToast } from "@chakra-ui/react"
import axios from "axios"
import _ from "lodash"
import { displayHydraError, getUrlWithParams } from "utils"

// configuration par défaut d'axios
export const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASEURL,
  headers: {
    "Content-Type": "application/json",
  },
})

// récupération de l'idToken, lève une erreur si celui-ci ne peut pas être récupéré
export const getIdToken = async (getIdTokenClaims) => {
  const idTokenClaims = await getIdTokenClaims()
  if (!idTokenClaims) {
    throw new Error("logout")
  }
  return idTokenClaims.__raw
}

/**
 * Méthode pour un simple Get quand l'utilisation de useApiGet n'est pas possible.
 */
export const apiGet = async (
  getIdTokenClaims,
  url,
  params = null,
  display = true,
  propagate = true
) => {
  const urlWithParams = getUrlWithParams(url, params)

  try {
    const idToken = await getIdToken(getIdTokenClaims)
    const response = await api.get(urlWithParams, {
      headers: { Authorization: `Bearer ${idToken}`, "Accept-Language": getLocale() },
    })

    return response.data
  } catch (error) {
    if (propagate) {
      throw error
    }
  }
}

// hook générique pour les GET
export const useApiGet = (
  url,
  params = null,
  redirectIf404 = true,
  displayError = true,
  queryOptions = null,
  propagate = false
) => {
  const { getIdTokenClaims, logout } = useAuth0()
  const history = useHistory()
  const toast = useToast()
  const urlWithParams = getUrlWithParams(url, params)

  return useQuery(
    [url, params],
    async () => {
      try {
        const idToken = await getIdToken(getIdTokenClaims)
        const response = await api.get(urlWithParams, {
          headers: { Authorization: `Bearer ${idToken}`, "Accept-Language": getLocale() },
        })

        return response.data
      } catch (error) {
        if (error?.message === "logout") {
          logout()
        } else {
          if (redirectIf404 && error?.response?.status === 404) {
            history.push("/404")
          }
          if (displayError && error?.response?.status !== 404) {
            displayHydraError(toast, error)
          }
          if (propagate) {
            throw error
          }
        }
      }
    },
    queryOptions
  )
}

// hook générique pour les GET file
export const useApiGetFile = (
  url,
  params = null,
  displayError = false,
  queryOptions = null,
  propagate = false
) => {
  const { getIdTokenClaims, logout } = useAuth0()
  const toast = useToast()
  const urlWithParams = getUrlWithParams(url, params)
  const key = urlWithParams === "" ? null : urlWithParams

  return useQuery(
    key,
    async () => {
      try {
        const idToken = await getIdToken(getIdTokenClaims)
        const response = await api.get(urlWithParams, {
          headers: { Authorization: `Bearer ${idToken}`, "Accept-Language": getLocale() },
          responseType: "arraybuffer",
        })

        const data = `data:${response.headers["content-type"]};base64,${Buffer.from(
          response.data,
          "binary"
        ).toString("base64")}`

        return data
      } catch (error) {
        if (error?.message === "logout") {
          logout()
        } else {
          if (displayError && error?.response?.status !== 404) {
            displayHydraError(toast, error)
          }
          if (propagate) {
            throw error
          }
        }
      }
    },
    queryOptions
  )
}

// hook générique pour les POST
export const useApiPost = (
  url,
  params = null,
  itemKey = null,
  displayError = true,
  propagate = true
) => {
  const queryClient = useQueryClient()
  const { getIdTokenClaims, logout } = useAuth0()
  const toast = useToast()
  const urlWithParams = getUrlWithParams(url, params)

  return useMutation(
    async (values) => {
      try {
        const idToken = await getIdToken(getIdTokenClaims)
        const response = await api.post(urlWithParams, values, {
          headers: { Authorization: `Bearer ${idToken}`, "Accept-Language": getLocale() },
        })

        return response.data
      } catch (error) {
        if (error?.message === "logout") {
          logout()
        } else {
          if (displayError) {
            displayHydraError(toast, error)
          }
          if (propagate) {
            throw error
          }
        }
      }
    },
    {
      onSettled: (values) => {
        if (itemKey) {
          queryClient.invalidateQueries(itemKey)
        }
      },
    }
  )
}

// hook générique pour les UPLOADS
export const useApiPostFile = (url, displayError = true, propagate = false) => {
  const { getIdTokenClaims, logout } = useAuth0()
  const toast = useToast()

  return useMutation(async ({ file, filename }) => {
    try {
      const idToken = await getIdToken(getIdTokenClaims)
      let form = new FormData()
      form.append("file", file, filename)
      const response = await api.post(url, form, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "multipart/form-data",
          "Accept-Language": getLocale(),
        },
      })

      return response.data
    } catch (error) {
      if (error?.message === "logout") {
        logout()
      } else {
        if (displayError) {
          displayHydraError(toast, error)
        }
        if (propagate) {
          throw error
        }
      }
    }
  })
}

// hook générique pour les PUT
export const useApiPut = (params = null, itemKey = null, displayError = true, propagate = true) => {
  const queryClient = useQueryClient()
  const { getIdTokenClaims, logout } = useAuth0()
  const toast = useToast()

  return useMutation(
    async (values) => {
      try {
        const urlWithParams = getUrlWithParams(values?.put_url, params)
        const idToken = await getIdToken(getIdTokenClaims)
        const response = await api.put(urlWithParams, _.omit(values, ["put_url"]), {
          headers: { Authorization: `Bearer ${idToken}`, "Accept-Language": getLocale() },
        })

        return response.data
      } catch (error) {
        if (error?.message === "logout") {
          logout()
        } else {
          if (displayError) {
            displayHydraError(toast, error)
          }
          if (propagate) {
            throw error
          }
        }
      }
    },
    {
      onMutate: async (values) => {
        if (itemKey) {
          await queryClient.cancelQueries(itemKey)
          const previousValues = queryClient.getQueryData(itemKey)

          return previousValues
        }

        return values
      },
      onError: (previousValues) => {
        if (itemKey) {
          queryClient.setQueryData(itemKey, previousValues)
        }
      },
      onSettled: (values) => {
        if (itemKey) {
          queryClient.invalidateQueries(itemKey)
        }
      },
    }
  )
}

// hook générique pour les PATCH
export const useApiPatch = (
  params = null,
  itemKey = null,
  displayError = true,
  propagate = true
) => {
  const queryClient = useQueryClient()
  const { getIdTokenClaims, logout } = useAuth0()
  const toast = useToast()

  return useMutation(
    async (values) => {
      try {
        const urlWithParams = getUrlWithParams(values?.put_url, params)
        const idToken = await getIdToken(getIdTokenClaims)
        const response = await api.patch(urlWithParams, _.omit(values, ["put_url"]), {
          headers: { Authorization: `Bearer ${idToken}`, "Accept-Language": getLocale() },
        })

        return response.data
      } catch (error) {
        if (error?.message === "logout") {
          logout()
        } else {
          if (displayError) {
            displayHydraError(toast, error)
          }
          if (propagate) {
            throw error
          }
        }
      }
    },
    {
      onMutate: async (values) => {
        if (itemKey) {
          await queryClient.cancelQueries(itemKey)
          const previousValues = queryClient.getQueryData(itemKey)

          return previousValues
        }

        return values
      },
      onError: (previousValues) => {
        if (itemKey) {
          queryClient.setQueryData(itemKey, previousValues)
        }
      },
      onSettled: (values) => {
        if (itemKey) {
          queryClient.invalidateQueries(itemKey)
        }
      },
    }
  )
}

// hook générique pour les DELETE
export const useApiDelete = (url, displayError = true, propagate = false) => {
  const { getIdTokenClaims, logout } = useAuth0()
  const toast = useToast()

  return useMutation(async (postId) => {
    try {
      const idToken = await getIdToken(getIdTokenClaims)
      const response = await api.delete(`${url}/${postId}`, {
        headers: { Authorization: `Bearer ${idToken}`, "Accept-Language": getLocale() },
      })

      return response.data
    } catch (error) {
      if (error?.message === "logout") {
        logout()
      } else {
        if (displayError) {
          displayHydraError(toast, error)
        }
        if (propagate) {
          throw error
        }
      }
    }
  })
}

import { useEffect, useState } from "react"
import { setLocale } from "react-i18nify"
import { useHistory } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"
import _ from "lodash"
import { langAtom, useAtom } from "store"
import { useGetMe } from "hooks/useGetMe"

// seuls les utilisateurs dont l'organisation correspond à ce nom ont le droit d'utiliser la webapp
const authorizedOrg = process.env.REACT_APP_AUTHORIZED_ORG

export const useGetProfile = () => {
  const [lang, setLang] = useAtom(langAtom)
  const { isLoading, error, logout } = useAuth0()
  const {
    data: collaborator,
    isLoading: loadingCollaborator,
    error: errorCollaborator,
  } = useGetMe(false, true, { staleTime: 30000 })
  const [organization, setOrganization] = useState(null)
  const [role, setRole] = useState(null)
  const history = useHistory()

  useEffect(() => {
    if (!loadingCollaborator) {
      const collaboratorOrganization = _.get(collaborator, "organization", {})
      const collaboratorRole = _.get(collaborator, "account.role", "")
      const defaultLocale = _.get(collaborator, "account.defaultLocale", "en")
      setOrganization(collaboratorOrganization)
      setRole(collaboratorRole)
      if (defaultLocale !== lang) {
        setLang(defaultLocale)
        setLocale(defaultLocale)
      }
    }
  }, [collaborator, loadingCollaborator, lang, setLang, setOrganization, setRole])

  useEffect(() => {
    if (
      !_.isNull(organization) &&
      organization.name?.toLowerCase() !== authorizedOrg?.toLowerCase()
    ) {
      history.push("/401")
    }
  }, [organization, history])

  useEffect(() => {
    if (!_.isNull(role) && role !== "admin" && role !== "manager") {
      history.push("/401")
    }
  }, [role, history])

  if (error || errorCollaborator) {
    history.push("/401")
    logout()
  }

  return { collaborator, organization, role, loading: isLoading || loadingCollaborator }
}

import { Fragment } from "react"
import { Flex, Image } from "@chakra-ui/react"
import PropTypes from "prop-types"
import { getFileUrl } from "utils"
import _ from "lodash"

export const DisplayImages = ({ equipment, images }) => (
  <Flex justifyContent="space-between" alignItems="center">
    {images.map((image) => (
      <Fragment key={image.position}>
        {equipment[image.position] && (
          <Flex position="relative" width="45%" height="200px" mb={2} justifyContent="center" alignItems="center">
            <Image
              src={getFileUrl(_.get(equipment[image.position], "file", null))}
              alt={image.label}
              position="absolute"
              top="50%"
              left="50%"
              transform="translate(-50%, -50%)"
              maxW="100%"
              maxH="100%"
            />
          </Flex>
        )}
      </Fragment>
    ))}
  </Flex>
)

DisplayImages.propTypes = {
  equipment: PropTypes.object.isRequired,
  images: PropTypes.array.isRequired,
}

import { useRef } from "react"
import { translate, Translate } from "react-i18nify"
import { useQueryClient } from "react-query"
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
  useColorMode,
  useDisclosure,
  useToast,
} from "@chakra-ui/react"
import { useUpdateDTO } from "hooks"
import { constants } from "utils"

export const DeclareAsSoldAction = ({ equipment, onSuccess }) => {
  const queryClient = useQueryClient()
  const toast = useToast()
  const { colorMode } = useColorMode()
  const textColor = colorMode === "dark" ? "white" : "black"
  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()
  const { mutate: updateDTO, isLoading } = useUpdateDTO()
  const equipmentStatus = equipment?.currentDigitalTwinOwner?.ownerStatus

  if (!equipmentStatus || equipmentStatus === "sold") {
    return null
  }

  const declareAsSold = () => {
    updateDTO(
      {
        put_url: equipment.currentDigitalTwinOwner?.["@id"],
        ownerStatus: "sold",
      },
      {
        onSuccess: () => {
          toast({
            title: translate("pages.view.asset_declared_as_sold"),
            description: "",
            status: "success",
            duration: constants.TOAST_ERROR_DURATION,
            isClosable: true,
          })
          queryClient.invalidateQueries("/equipment", {
            erpId: equipment.erpId,
            "group[]": "api-equipment",
          })
          onSuccess?.()
        },
      }
    )
    onClose()
  }

  return (
    <>
      <Button
        size="lg"
        my={5}
        mx={2}
        w="180px"
        colorScheme="red"
        disabled={isLoading}
        onClick={onOpen}
        isLoading={isLoading}
        loadingText={translate("common.submitting")}
      >
        <Translate value="pages.view.declare_as_sold" />
      </Button>
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold" color={textColor}>
              <Translate value="common.confirmation" />
            </AlertDialogHeader>
            <AlertDialogBody color={textColor}>
              <Translate value="pages.view.declare_as_sold_confirm" />
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                <Translate value="common.cancel" />
              </Button>
              <Button colorScheme="red" onClick={declareAsSold} ml={3}>
                <Translate value="common.yes" />
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

import React from "react"
import { BrowserRouter, Switch } from "react-router-dom"
import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import { ChakraProvider, ColorModeProvider } from "@chakra-ui/react"
import { Auth0ProviderHistory, AuthRoute } from "components/auth"
import { Error, Home, Save, Scan, View } from "pages"
import theme from "theme"

if (process.env.REACT_APP_API_MOCKING === "1") {
  require("./__mocks__")
}

// React Query configuration
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        if (error?.response?.status === 404 || error?.response?.status >= 500) {
          return false // no retry for some error codes
        }
        return 3
      },
    },
  },
})

const App = () => (
  <BrowserRouter>
    <ChakraProvider resetCSS theme={theme}>
      <ColorModeProvider options={{ useSystemColorMode: false, initialColorMode: "light" }}>
        <QueryClientProvider client={queryClient}>
          <Auth0ProviderHistory>
            <Switch>
              <AuthRoute exact authenticated={true} path="/" children={<Home />} />
              <AuthRoute exact authenticated={true} path="/scan" children={<Scan />} />
              <AuthRoute exact authenticated={true} path="/save/:ocode" children={<Save />} />
              <AuthRoute exact authenticated={true} path="/view/:ocode" children={<View />} />

              <AuthRoute authenticated={false} path="/401" children={<Error code={401} />} />
              <AuthRoute authenticated={false} path="/403" children={<Error code={403} />} />
              <AuthRoute authenticated={false} path="*" children={<Error code={404} />} />
            </Switch>
          </Auth0ProviderHistory>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </ColorModeProvider>
    </ChakraProvider>
  </BrowserRouter>
)

export default App

import { useApiGet } from "apis/sharemat"
import { useHydraList } from "hooks"
import { getHydraId } from "utils"

export const useGetModels = ({
  organization,
  category = null,
  brand = null,
  pageSize = 1000,
  redirectIf404 = false,
  enabled = true,
}) => {
  const params = {
    organization: getHydraId(organization),
    category: getHydraId(category),
    brand: getHydraId(brand),
    pageSize,
  }
  const {
    data: hydraData,
    isLoading,
    error,
  } = useApiGet(`/referential/models`, params, redirectIf404, true, { enabled }, false)
  const data = useHydraList(hydraData)

  return { data, isLoading, error }
}

import { cloneElement } from "react"
import { withAuthenticationRequired } from "@auth0/auth0-react"
import { Route } from "react-router-dom"
import { ErrorBoundary } from "@sentry/react"
import { ErrorFallback } from "components/common"
import { AuthContact } from "components/auth"
import { DefaultLayout, LoadingLayout } from "layouts"
import PropTypes from "prop-types"

// Component va correspondre à un des composants contenus
const Component = ({ children, Layout, ...rest }) => (
  <Layout>
    <ErrorBoundary fallback={ErrorFallback}>{cloneElement(children, { ...rest })}</ErrorBoundary>
  </Layout>
)

const AuthorizedComponent = (props) => <AuthContact PageComponent={Component} {...props} />

const AuthenticatedComponent = withAuthenticationRequired(AuthorizedComponent, {
  onRedirecting: () => <LoadingLayout />,
})

export const AuthRoute = ({ children, authenticated = true, Layout = DefaultLayout, ...rest }) => (
  <Route {...rest}>
    {authenticated && <AuthenticatedComponent Layout={Layout}>{children}</AuthenticatedComponent>}
    {!authenticated && <Component Layout={Layout}>{children}</Component>}
  </Route>
)

AuthRoute.propTypes = {
  authenticated: PropTypes.bool,
  Layout: PropTypes.elementType,
}

import { useEffect, useState } from "react"
import _ from "lodash"
import { langAtom, useAtom } from "store"

export const useDocumentTitle = (newTitle = null) => {
  const [lang] = useAtom(langAtom)
  const [title, setTitle] = useState(newTitle)

  useEffect(() => {
    if (newTitle) {
      setTitle(newTitle)
    }
  }, [lang, newTitle, setTitle])

  useEffect(() => {
    if (title) {
      document.title = `${_.escape(title)} - Uperio Assets`
    }
  }, [title, setTitle])

  return { title, setTitle }
}

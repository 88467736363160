export const constants = {
  DEFAULT_LANG: "en",
  LANGS: ["fr", "en", "de"],
  EQUIPMENT_DEFAULT_TYPE: "small_equipment",
  EQUIPMENT_DEFAULT_STATUS: "working",
  EQUIPMENT_PICTURE_WIDTH: 400,
  EQUIPMENT_SELECTED_PICTURES: [
    { position: "mainImage", label: "entity.main_photo", required: true },
    { position: "frontImage", label: "entity.nameplate_photo", required: false },
    { position: "deviceImage", label: "entity.flashcode_photo", required: true },
  ],
  STORAGE_KEYS_TO_CLEAN: ["welcome"],
  TOAST_INFO_DURATION: 6000,
  TOAST_SUCCESS_DURATION: 4000,
  TOAST_WARNING_DURATION: 8000,
  TOAST_ERROR_DURATION: 8000,
}

import { useHistory } from "react-router-dom"
import { Auth0Provider } from "@auth0/auth0-react"
import { cleanLocalStorage } from "utils"

export const Auth0ProviderHistory = ({ children }) => {
  const history = useHistory()

  const onRedirectCallback = (appState) => {
    cleanLocalStorage()
    history.push(appState?.returnTo || window.location.pathname)
  }

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENTID}
      authorizationParams={{ redirect_uri: window.location.origin }}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  )
}

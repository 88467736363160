import { useState } from "react"
import { translate, Translate } from "react-i18nify"
import PropTypes from "prop-types"
import { ChevronLeftIcon } from "@chakra-ui/icons"
import { Button, Flex, Stack, Text } from "@chakra-ui/react"
import { DeclareAsSoldAction, EquipmentDetail } from "components/common"

export const EquipmentPreview = ({ equipment, onPrev, onSubmit, loading = false }) => {
  const [current, setCurrent] = useState(equipment)

  const declareAsSold = () => {
    setCurrent((prev) => ({
      ...prev,
      currentDigitalTwinOwner: { ...prev.currentDigitalTwinOwner, ownerStatus: "sold" },
    }))
  }

  return (
    <Stack spacing={10}>
      <Text>
        <Translate value="pages.view.check_your_entry" />
      </Text>
      <EquipmentDetail equipment={current} />

      <Flex justifyContent="space-around" alignItems="center" flexWrap="wrap">
        <Button
          id="submit-asset"
          size="lg"
          my={5}
          mx={2}
          w="180px"
          isLoading={loading}
          loadingText={translate("common.submitting")}
          onClick={() => onSubmit(equipment)}
          translate="no"
        >
          <Translate value="common.submit" />
        </Button>
        <DeclareAsSoldAction equipment={current} onSuccess={declareAsSold} />
      </Flex>
      <Flex justifyContent="center">
        <Button
          ms={0}
          width="260px"
          colorScheme="gray"
          leftIcon={<ChevronLeftIcon />}
          disabled={loading}
          onClick={onPrev}
        >
          <Text as="span">
            <Translate value="pages.view.go_back_and_edit" />
          </Text>
        </Button>
      </Flex>
    </Stack>
  )
}

EquipmentPreview.propTypes = {
  equipment: PropTypes.object.isRequired,
  onPrev: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

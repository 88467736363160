import { Translate } from "react-i18nify"
import { Flex, Heading, ListItem, Text, UnorderedList } from "@chakra-ui/react"
import { PageTitle } from "components/common"

export const CameraNotSupported = () => (
  <>
    <PageTitle>
      <Translate value="pages.scan.camera_not_supported.title" />
    </PageTitle>
    <Text fontStyle="xl" textAlign="center">
      <Translate value="pages.scan.camera_not_supported.text" />
    </Text>
    <Text textAlign="center">
      <Translate value="pages.scan.camera_not_supported.instructions" />
    </Text>
    <Heading size="sm" textAlign="center">
      <Translate value="pages.scan.camera_not_supported.ios_title" />
    </Heading>
    <Flex justifyContent="center" alignItems="center">
      <UnorderedList minWidth="250px" px="1.5rem">
        <ListItem>
          <Translate value="pages.scan.camera_not_supported.ios_step1" />
        </ListItem>
        <ListItem>
          <Translate value="pages.scan.camera_not_supported.ios_step2" />
        </ListItem>
        <ListItem>
          <Translate value="pages.scan.camera_not_supported.ios_step3" />
        </ListItem>
        <ListItem>
          <Translate value="pages.scan.camera_not_supported.ios_step4" />
        </ListItem>
        <ListItem>
          <Translate value="pages.scan.camera_not_supported.ios_step5" />
        </ListItem>
      </UnorderedList>
    </Flex>
    <Heading size="sm" textAlign="center">
      <Translate value="pages.scan.camera_not_supported.android_title" />
    </Heading>
    <Flex justifyContent="center" alignItems="center">
      <UnorderedList maxWidth="250px">
        <ListItem>
          <Translate value="pages.scan.camera_not_supported.android_step1" />
        </ListItem>
        <ListItem>
          <Translate value="pages.scan.camera_not_supported.android_step2" />
        </ListItem>
        <ListItem>
          <Translate value="pages.scan.camera_not_supported.android_step3" />
        </ListItem>
        <ListItem>
          <Translate value="pages.scan.camera_not_supported.android_step4" />
        </ListItem>
        <ListItem>
          <Translate value="pages.scan.camera_not_supported.android_step5" />
        </ListItem>
        <ListItem>
          <Translate value="pages.scan.camera_not_supported.android_step6" />
        </ListItem>
      </UnorderedList>
    </Flex>
  </>
)

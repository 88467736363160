import { Flex, useColorMode } from "@chakra-ui/react"
import { SharematIcon } from "components/icons"
import { Link } from "components/common"
import { MainMenu } from "layouts/common"

export const Header = () => {
  const { colorMode } = useColorMode()
  const logoColor = colorMode === "dark" ? "#fff" : "#081522"

  return (
    <Flex width="100%" justifyContent="space-between" alignItems="center" py="1rem">
      <MainMenu />
      <Flex alignItems="center">
        <Link to="/">
          <SharematIcon data-testid="header-logo" w={130} h={50} px={4} logoColor={logoColor} />
        </Link>
      </Flex>
    </Flex>
  )
}

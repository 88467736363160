import { useField } from "react-final-form"
import { FormLabel, NumberInput, NumberInputField } from "@chakra-ui/react"
import { Control, Error } from "components/form-fields"

export const NumberControl = ({
  name,
  label,
  placeholder = null,
  step = 1,
  min = 0,
  max = null,
  ...rest
}) => {
  const { input, meta } = useField(name)
  return (
    <Control name={name} my={4}>
      <FormLabel htmlFor={name}>{label}</FormLabel>

      <NumberInput
        defaultValue={input?.value}
        step={step}
        min={min}
        max={max}
        isInvalid={meta.error && meta.touched}
        {...input}
      >
        <NumberInputField {...input} id={name} placeholder={placeholder || label} {...rest} />
      </NumberInput>
      <Error name={name} />
    </Control>
  )
}

import { translate, Translate } from "react-i18nify"
import { AiTwotoneHome } from "react-icons/ai"
import { useAuth0 } from "@auth0/auth0-react"
import { HamburgerIcon, MoonIcon, SearchIcon, SunIcon, UnlockIcon } from "@chakra-ui/icons"
import {
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  useColorMode,
} from "@chakra-ui/react"
import { Link } from "components/common"

export const MainMenu = () => {
  const { isAuthenticated, logout } = useAuth0()
  const { colorMode, toggleColorMode } = useColorMode()
  const isDark = colorMode === "dark"

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        minWidth={10}
        aria-label={translate("menu.title")}
        icon={<HamburgerIcon />}
        variant="outline"
      />
      <MenuList data-testid="main-menu-list">
        <Link to="/" textDecoration="none">
          <MenuItem icon={<Icon as={AiTwotoneHome} />}>
            <Translate value="menu.home" />
          </MenuItem>
        </Link>
        <Link to="/scan" textDecoration="none">
          <MenuItem icon={<SearchIcon />}>
            <Translate value="menu.scan" />
          </MenuItem>
        </Link>
        <MenuItem icon={isDark ? <SunIcon /> : <MoonIcon />} onClick={toggleColorMode}>
          {isDark ? (
            <Translate value="menu.switch_light" />
          ) : (
            <Translate value="menu.switch_dark" />
          )}
        </MenuItem>
        <MenuDivider />
        <MenuItem
          color="red.500"
          fontWeight="bold"
          icon={<UnlockIcon />}
          onClick={logout}
          isDisabled={!isAuthenticated}
        >
          <Translate value="menu.logout" />
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

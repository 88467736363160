import { useApiGet } from "apis/sharemat"
import { useHydraList } from "hooks"
import { getHydraId } from "utils"

export const useGetBrands = ({ organization, pageSize = 1000, redirectIf404 = false }) => {
  const params = { organization: getHydraId(organization), pageSize }
  const { data: hydraData, isLoading, error } = useApiGet(
    `/referential/brands`,
    params,
    redirectIf404
  )
  const data = useHydraList(hydraData)

  return { data, isLoading, error }
}

import Resizer from "react-image-file-resizer"
import _ from "lodash"

export const strDef = (s) => typeof s != "undefined" && s !== null && s !== ""

export const getUrlWithParams = (url, params, all = true, noNull = false) => {
  if (_.isNull(params) || _.isUndefined(params)) {
    return url
  }

  let count = 0,
    query = ""
  for (let key in params) {
    if (all || params[key]) {
      query += count === 0 ? "" : "&"

      if (_.isNull(params[key]) && noNull) {
        query += key
        count++
      } else if (!_.isUndefined(params[key])) {
        query += key + "=" + encodeURIComponent(params[key])
        count++
      }
    }
  }

  return `${url}${query ? "?" : ""}${query}`
}

export const resizeImage = (image, maxWidth = 1000, maxHeight = 1000, quality = 80) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      image,
      maxWidth,
      maxHeight,
      "JPEG",
      quality,
      0,
      (uri) => {
        resolve(uri)
      },
      "blob"
    )
  })

import { translate } from "react-i18nify"
import { useHistory } from "react-router-dom"
import { useToast } from "@chakra-ui/react"
import { useDocumentTitle } from "hooks"
import { ScanOcode } from "components/common"
import { constants } from "utils"

const Scan = () => {
  useDocumentTitle(translate("pages.scan.title"))
  const history = useHistory()
  const toast = useToast()

  const onDetect = (ocode) => {
    history.push(`/save/${ocode}`)
    const toastId = "toast-ocode-detected"

    // pour éviter d'avoir un double message si detectOcode déclenche 2x fois onDetect
    if (!toast.isActive(toastId)) {
      toast({
        id: toastId,
        title: translate("pages.scan.flashcode_detected", { code: ocode }),
        status: "success",
        duration: constants.TOAST_SUCCESS_DURATION,
        isClosable: true,
      })
    }
  }

  return <ScanOcode onDetect={onDetect} />
}

export default Scan

import { useField } from "react-final-form"
import { FormLabel, Input } from "@chakra-ui/react"
import { Control, Error } from "components/form-fields"

export const InputControl = ({ name, label, placeholder = null, ...rest }) => {
  const { input, meta } = useField(name)
  return (
    <Control name={name} my={4}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Input
        {...input}
        isInvalid={meta.error && meta.touched}
        id={name}
        placeholder={placeholder || label}
        {...rest}
      />
      <Error name={name} />
    </Control>
  )
}

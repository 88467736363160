import { Field } from "react-final-form"
import { translate } from "react-i18nify"
import { useAuth0 } from "@auth0/auth0-react"
import { Flex, FormLabel, Input, Spinner } from "@chakra-ui/react"
import _ from "lodash"
import { apiGet } from "apis/sharemat"
import { Control, Error } from "components/form-fields"

export const ReferenceControl = ({ name, label, placeholder = null, ...rest }) => {
  const { getIdTokenClaims } = useAuth0()

  // vérifie si la référence est disponible (non déjà utilisée)
  const referenceAvailable = async (value, values, meta) => {
    if (_.isNil(value) || value === "") {
      return false
    }

    if (!meta.active) {
      return meta.invalid ? meta.error : false
    }

    return await apiGet(getIdTokenClaims, "/equipment", {
      "ref[]": value,
      "group[]": "api-equipment",
    })
      .then((result) =>
        // filter : on ne veut pas prendre en compte l'equipment en cours si édition
        result?.["hydra:member"]?.filter((equipment) => equipment["@id"] !== values["@id"]).length >
        0
          ? translate("pages.save.reference_already_used")
          : false
      )
      .catch(() => false)
  }

  return (
    <Field name={name} validate={referenceAvailable}>
      {({ input, meta }) => (
        <Control name={name} my={4}>
          <Flex>
            <FormLabel htmlFor={name}>{label}</FormLabel>
            {meta.validating && <Spinner />}
          </Flex>
          <Input
            {...input}
            isInvalid={meta.error && meta.touched}
            id={name}
            placeholder={placeholder || label}
            {...rest}
          />
          <Error name={name} />
        </Control>
      )}
    </Field>
  )
}

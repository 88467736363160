import { Translate } from "react-i18nify"
import { useAuth0 } from "@auth0/auth0-react"
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Stack,
  useColorMode,
} from "@chakra-ui/react"
import { Container, Footer, Header, Main } from "layouts/common"

export const DefaultLayout = ({ children }) => {
  const { error } = useAuth0()
  const { colorMode } = useColorMode()
  const bgColor = { light: "gray.50", dark: "gray.900" }
  const color = { light: "black", dark: "white" }

  return (
    <Stack bg={bgColor[colorMode]} color={color[colorMode]}>
      <Container minHeight="100vh">
        <Header />
        <Main>{!error && <>{children}</>}</Main>
        <Footer />
      </Container>
      {error && (
        <Alert status="error" position="fixed" bottom="0">
          <AlertIcon />
          <AlertTitle mr={2}>
            <Translate value="common.login_error" />
          </AlertTitle>
          <AlertDescription>{error?.message}</AlertDescription>
        </Alert>
      )}
    </Stack>
  )
}
